<template>
  <div id="trivia" class="has-text-black-bis">
    <!-- HOW TO PLAY -->
    <section class="section p-0" v-if="serverState < 2">
      <div class="waiting is-flex is-flex-direction-column has-background-white-ter has-text-black-bis">
        <div class="top p-5">
          <div class="mb-5 has-text-weight-bold">{{ $t('exp_trivia.howToPlay') }}</div>
          <div class="tutorial p-5 is-flex is-justify-content-center">
            <img src="/assets/trivia/tutorial.png" alt="How to play" class="image" />
          </div>
          <p>{{ $t('exp_trivia.readTheQuestion') }}</p>
          <p v-if="options.tutorialExtraText" class="mt-2">{{options.tutorialExtraText}}</p>
        </div>
        <div v-if="moreOnePlayer" class="bottom is-flex is-align-items-center is-justify-content-center has-text-primary">
          <span class="is-size-5">{{ $t('exp_trivia.waitingPlayers') }}</span>
        </div>
      </div>
    </section>

    <!-- GAME STARTED -->
    <section class="game-started section p-0" v-if="serverState == 2" :class="questionStateClass">

      <!--  GET READY -->
      <div v-if="questionState == 0"
        class="stategetready is-flex is-flex-direction-column is-justify-content-center is-align-items-center p-5 has-text-white-bis">

        <div class="circle is-size-2 mb-3 has-text-weight-bold" >
          <svg viewBox="0 0 576 512">
            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
          </svg>
        </div>
        <div class="mb-5 is-size-4 has-text-weight-bold">{{ $t('exp_trivia.getReadyToPlay') }}</div>
        <div class="has-text-centered">{{options.config.getReadyExtraText}}</div>
      </div>

      <template v-if="questionState > 0 && currQuestion != null">
        <div class="is-flex is-flex-direction-column">
          <div class="trivia-main has-background-grey-lighter"
            :key="currQuestionIndex"
            :class="questionState > 1 ? 'overflow-scroll' : 'overflow-hidden'">
            <!-- PREGUNTA -->
            <div class="question-header has-text-weight-bold has-image">
              <div class="question-number is-flex py-2 px-5">
                <!-- pregunta header -->
                <span class="is-uppercase">{{ $t('exp_trivia.questionNumber',[currQuestionIndex + 1,selectedQuestions.length])}}</span>
                <!-- pregunta timer -->
                <progress-circle
                  :class="{ inactive: (myGuess && questionState != 3 ) || clockNormalized >= 1 }"
                  :diameter="59"
                  :circle-width="7"
                  :completed-steps="clockNormalized * 100"
                  :total-steps="100"
                  circle-color="#DBDBDB"
                  start-color="var(--timer-fill-start)"
                  stop-color="var(--timer-fill-stop)"
                  inner-color="var(--timer-inner)"
                  :animation-duration="10">
                  <span class="has-text-weight-bold">
                    {{ clockDisplay }}
                  </span>
                </progress-circle>
              </div>
              <!-- pregunta prompt -->
              <div class="question-prompt p-5 is-flex is-align-items-center">
                <div
                  class="prompt is-size-5 has-text-centered has-text-white-bis">
                <img v-if="currQuestion.image" :src="currQuestion.image.sizes.mobile.url ? currQuestion.image.sizes.mobile.url : currQuestion.image.url">
                  {{ currQuestion.prompt }}
                </div>
              </div>
            </div>
            <!-- OPCIONES -->


            <div class="options-list p-5" v-if="questionState > 1" :class="{ inactive: (myGuess && questionState != 3 ) || clockNormalized >= 1 }">
              <transition-group appear @before-enter="beforeEnter" @enter="enter">
                <b-button v-for="(answer, i) in currQuestion.options" :key="answer.id" class="option mb-4" :class="{
                  selected: isSelected(answer.id),
                  correctAnswer: isCorrect(answer.id) && questionState == 3,
                  incorrectAnswer: isSelected(answer.id) && !isCorrect(answer.id) && questionState == 3
                }" :data-index="i" expanded @click="SendAnswer(answer)">
                  <div>
                    <!-- <div class="letter pt-3" :class="{'has-background': !options.config.hideOptionsText }">
                      <div class="has-text-weight-bold">
                        {{ intToChar(i) }}
                      </div>
                    </div> -->
                    <div v-if="!options.config.hideOptionsText" class="text is-flex is-align-items-center px-4 py-2">
                      <div>
                        {{ answer.option }}
                      </div>
                    </div>
                  </div>
                  <div class="result px-5">
                    <svg v-if="isCorrect(answer.id) && myGuess && myGuess.id == answer.id && questionState == 3" viewBox="0 0 320 512">
                      <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                    </svg>
                    <svg v-if="!isCorrect(answer.id) && myGuess && myGuess.id == answer.id && questionState == 3" viewBox="0 0 320 512">
                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                    </svg>
                  </div>
                </b-button>
              </transition-group>
            </div>
          </div>
          <!-- Footer -->
          <div
            class="player-standing-footer py-4 px-5 is-flex is-justify-content-space-between">
            <div>
              <span v-if="myStanding.standing" class="has-text-weight-bold">
                {{myStanding.standing + $t('exp_trivia.nPlace') }}
              </span>
            </div>
            <div>
              <span>{{ $t('exp_trivia.yourScore') }}:</span>
              <span class="ml-1 has-text-weight-bold">{{ myStanding.score ? Math.round(myStanding.score) : 0 }}</span>
            </div>
            <b-notification
              v-if="waitingResponses"
              type="is-primary" 
              :closable="false">
              <div class="hourglass">
                <d-icon
                  pack="fas"
                  icon="hourglass-half"
                  size="is-medium"/>
              </div>
              <div class="content">{{$t('exp_trivia.waitingResponses')}}</div>
            </b-notification>
          </div>
        </div>

        <!-- RESULT -->
        <div id="guessResult">
          <!-- TOP Section (time is up / incorrect / correct) -->
          <div  id="guessResultNewScoreTop" class="top has-text-white-bis" :class="[{ active: showGuessResult}, questionResult]">
            <template v-if="questionResult == 'timesup'">
              <svg viewBox="0 0 384 512">
                <path d="M0 32C0 14.3 14.3 0 32 0H64 320h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V75c0 42.4-16.9 83.1-46.9 113.1L237.3 256l67.9 67.9c30 30 46.9 70.7 46.9 113.1v11c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 64 32c-17.7 0-32-14.3-32-32s14.3-32 32-32V437c0-42.4 16.9-83.1 46.9-113.1L146.7 256 78.9 188.1C48.9 158.1 32 117.4 32 75V64C14.3 64 0 49.7 0 32zM96 64V75c0 25.5 10.1 49.9 28.1 67.9L192 210.7l67.9-67.9c18-18 28.1-42.4 28.1-67.9V64H96zm0 384H288V437c0-25.5-10.1-49.9-28.1-67.9L192 301.3l-67.9 67.9c-18 18-28.1 42.4-28.1 67.9v11z"/>
              </svg>
              <div class="is-size-3 is-uppercase has-text-centered">{{ $t('exp_trivia.timeUp') }}</div>
            </template>
            <template v-if="questionResult == 'incorrect'">
              <svg viewBox="0 0 320 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
              </svg>
              <div class="is-size-3 is-uppercase has-text-centered">
                {{ $t('exp_trivia.incorrect') }}
              </div>
            </template>
            <template v-if="questionResult == 'correct'">
              <svg viewBox="0 0 512 512">
                <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
              </svg>
              <div class="is-size-3 is-uppercase has-text-centered">
                {{ $t('exp_trivia.correct') }}
              </div>
            </template>
          </div>
          <!-- bottom section (points) -->
          <div id="guessResultNewScoreBottom" class="bottom has-text-weight-bold" :class="{ active: showQuestionScore }">
            <div
              class="points is-flex  is-flex-direction-column is-justify-content-center p-5">
              <div class="is-size-1 mb-5 has-text-centered">
                +
                <animated-number :initialNumber="questionPoints || 0" :number="scoreDisplay.bonus || 0"
                  :duration=".7" />
              </div>
              <div>
                <div class="is-size-4 has-text-centered is-uppercase">{{ $t('exp_trivia.yourScore') }}:</div>
                <div class="is-size-1 has-text-centered">
                  <animated-number :number="scoreDisplay.score" :duration=".7" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>

    <section v-if="matchFinished"
      class="match-finished has-text-white-bis section p-5 is-flex is-flex-direction-column justify-content-space-between"
      :class="myStanding.standing == 1 ? 'winner' : 'loser'">
          <!-- Scoreboard -->
          <div class="is-flex is-flex-direction-column is-align-content-center is-justify-content-center has-text-centered">
            <div v-if="standings[0]?.score > 0">
              <div class="is-size-2">
                <!-- check si gano -->
                <svg v-if="myStanding.standing == 1" class="win" viewBox="0 0 512 512">
                  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg>
                <!-- cruz si perdio -->
                <svg v-else class="lose" viewBox="0 0 320 512">
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                </svg>
              </div>
              <div class="is-size-3 has-text-weight-bold">
                <!-- 1ero -->
                <template v-if="myStanding.standing == 1">{{ $t('exp_trivia.youWin') }}</template>
                <!-- resto -->
                <template v-else>{{ $t('exp_trivia.youLost') }}</template>
              </div>
              <p class="is-size-5">
                <!-- 1ero -->
                <template v-if="myStanding.standing == 1">{{ $t('exp_trivia.onPodium') }}</template>
                <!-- 2do y 3ro -->
                <template v-else-if="myStanding.standing <= 3">
                  <template v-if="options.screenDisplay.type == 'screenCityTiles'">
                    {{ $t('exp_trivia.nextTime') }}
                  </template>
                  <template v-else>
                    {{ $t('exp_trivia.butOnPodium') }}
                  </template>
                </template>
                <!-- resto -->
                <template v-else>{{ $t('exp_trivia.nextTime') }}</template>
              </p>
            </div>
            <div v-else>
              <div class="is-size-2">
                <svg class="tied" viewBox="0 0 320 512">
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                </svg>
              </div>
              <div class="is-size-3 has-text-weight-bold">
                {{ $t('exp_trivia.youTied') }}
              </div>
              <p class="is-size-5">
                {{ $t('exp_trivia.noPlayerWon') }}
              </p>
            </div>
            <div class="mt-5 is-size-5 has-text-weight-bold">{{ $t('exp_trivia.yourScore') }}</div>
            <div class="is-size-3 has-text-weight-bold">{{ myStanding ? Math.round(myStanding.score) : 0 }}</div>
          </div>

          <div class="auto-height">
            <b-button type="is-primary" :label="$t('system.backToMenu')" expanded @click="$router.push('/')" />
          </div>

    </section>

    <div id="cosaspreload" style="display: none">
      <img v-for="(question, i) in options.questions.filter(x=>x.image)" :key="i" :src="question.image.sizes.mobile.url ? question.image.sizes.mobile.url : question.image.url" />
      
      <img v-if="options.controllerBackground" :src="this.options.controllerBackground.sizes.mobile.url ? this.options.controllerBackground.sizes.mobile.url : this.options.controllerBackground.url"/>
      <img v-else src="/assets/trivia/background.png"/>
    </div>
    <!-- cosas preload -->

  </div>
</template>

<script>
import TriviaOptionsShuffle from '../controllers/TriviaOptionsShuffle';
import AnimatedNumber from '../AnimatedNumber.vue';
import './TriviaController.scss';
import { ProgressCircle } from 'vue-progress-circle'
import gsap from "gsap";

export default {
  components: {
    AnimatedNumber,
    ProgressCircle
  },
  props: {},
  data() {
    return {
      questionState: -1,
      currQuestionIndex: -1,
      currQuestion: null,
      myGuess: null,
      myTimeouts: [],
      questionPoints: 0,
      standings: [],
      myStanding: {score:0},
      timerWebWorkers: [],
      remainingTimeQuestion: 0,
      remainingTimeQuestionString: "0",
      showQuestionTimer: false,
      questionMs: null,
      showOptions: false,
      showQuestionScore: false,
      scoreDisplay: {
        score: 0,
        bonus: 0,
      },
      selectedQuestions: [],
      gameSeed: null,
      questionSeed: null,
      showGuessResult: false,
      waitingResponses: false,
      questionResult: 'timeup'
    };
  },
  computed: {
    clockNormalized(){
      return this.remainingTimeQuestion
    },
    clockDisplay(){
      return Math.round(this.clockNormalized * (this.questionMs / 1000))
    },
    matchFinished(){
      return this.$parent.matchFinished
    },
    guessResultState() {
      if (!this.myGuess) return 0; // time is up
      if (this.myGuess.id != this.currQuestion.id) return 1; // incorrect
      return 2; // correct
    },
    guessResultClass() {
      const classes = { 0: "timesup", 1: "incorrect", 2: "correct" }
      return classes[this.guessResultState]
    },
    serverState() {
      return this.$parent.serverState
    },
    moreOnePlayer(){
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1
    },
    questionStateClass() {
      const states = { 0: 'stategetready', 1: 'stateprompt', 2: 'stateoptions', 3: 'stateresult' };
      return states[this.questionState];
    },
    bgControllerUrl() {
      return this.options.controllerBackground ? 
        'url(' + encodeURI(
          this.options.controllerBackground.sizes.mobile.url ? 
          this.options.controllerBackground.sizes.mobile.url : 
          this.options.controllerBackground.url) + ')' 
          : 'url(/assets/trivia/background.png)'
    },
    bgGradient() {
      return 'background: linear-gradient(180deg, #FE78BF, #86B744)';
    },
    options() {
      return this.$parent.room.experience.component[0]
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100%)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.5
      });
    },
    isSelected(id) {
      return this.myGuess && this.myGuess.id == id
    },
    isCorrect(id) {
      return id == this.currQuestion.id
    },
    Guess(option) {
      this.myGuess = option
    },
    intToChar(i) {
      return TriviaOptionsShuffle.intToChar(i)
    },
    SendAnswer(answer) {
      if (!this.showOptions) return
      if (this.myGuess != null || this.questionState == 3) return
      this.myGuess = answer
      this.$socket.client.emit('gameMessage', {
        type: "SendAnswer",
        answerid: answer.id,
      });
      this.$sfxPlay('click')
      // Freno el reloj
      this.timerWebWorkers.postMessage({ pause: true });
    },
    ShuffleQuestion() {
      console.log("shuffle question")
      const i = this.currQuestionIndex < 0 ? 0 : this.currQuestionIndex;
      const q = this.selectedQuestions[i]
      this.currQuestion = TriviaOptionsShuffle.GetOptions(q, this.questionSeed);
      this.myGuess = null
      this.remainingTimeQuestionStringDisplay = null
      this.remainingTimeQuestion = 1
    },

  },
  watch: {
    matchFinished(finished){
      if(finished){
        if(this.myStanding.standing == 1){
          this.$sfxPlay('winnerpodiummobile')
        }else{
          this.$sfxPlay('loser')
        }
      }
    },
    questionState(state) {
      console.log("Question state", state)
      // 0: get ready,  1: prompt (pregunta grande), 2: options, 3: result
      if (state == 0) {
        this.showOptions = false;
        this.questionPoints = 0;
        this.remainingTimeQuestionStringDisplay = null;
      }
      if (state == 3) {
        // Freno el reloj
        this.timerWebWorkers.postMessage({ pause: true });
        this.waitingResponses = false;
        
      }
    },
    serverState(state) {
      if (state == 1) {
        this.currQuestionIndex = -1;
        this.questionState = -1;
      }
      if (state == 2) {
        const getReadyDuration = (this.options.config.getReadyDuration || 3) * 1000
        this.timerWebWorkers.postMessage({ endTime: this.$time.now() + getReadyDuration, delay: this.$time.delay });
      }
    },
    currQuestionIndex(i) {
      this.showQuestionScore = false
      console.log("Question index", i)
      this.ShuffleQuestion()
      //this.$sfxPlay('newquestion')
      this.showGuessResult = false
    },
    showQuestionScore(show) {
      if (show) {
        // let scoreState = { 0: "timesup", 1: "incorrect", 2: "correct" }
        
        this.questionResult = this.guessResultClass

        this.scoreDisplay.bonus = this.guessResultState == 2 ? this.questionPoints : 0
        this.myTimeouts.push(setTimeout(() => {
          this.scoreDisplay.bonus = 0
          this.scoreDisplay.score = this.myStanding ? Math.round(this.myStanding.score) : 0
        }, 1000));
        if(this.guessResultState == 0){
          this.$sfxPlay('timeup')
        }else if(this.guessResultState == 1){
          this.$sfxPlay('incorrect')
        }else{
          this.$sfxPlay('correct')
        }
      }
    }
  },
  sockets: {
    gameQuestions(data){
      this.selectedQuestions = data.selectedQuestions
      console.log("Received game questions", this.selectedQuestions)
    },
    gameSeed(data) {
      // Deprecado?
      this.gameSeed = data.gameSeed
      const maxQuestions = this.options.config.limitQuestions ? this.options.config.amountQuestions : 999
      this.selectedQuestions = TriviaOptionsShuffle.GetQuestions(this.options.questions, this.gameSeed, maxQuestions)
    },
    questionDuration(data) {
      this.questionMs = data.questionMs;
      this.showQuestionTimer = true;
      this.remainingTimeQuestionString = this.questionMs.toString()
    },
    questionEndTime(data) {
      console.log("offset", Date.now() - this.$time.now())
      this.timerWebWorkers.postMessage({ endTime: data.endTime, delay: this.$time.delay });
      this.showOptions = true;
      // this.questionClockRunning = true;
    },
    questionPoints(data){
      console.log("✴️ Question Points", data)
      this.showGuessResult = true;
      this.questionPoints = Math.round(data.questionPoints);
      this.myStanding.score = Math.round(data.score);
      // Falta aplicar el puntaje total

      this.showQuestionScore = true
    },
    standings(data) {
      this.showQuestionTimer = false;
      this.standings = data.standings;
      const myStanding = this.standings.find(x => x.dbid == this.$store.state.user.profile.id)

      console.log("📈 Received standings", this.myStanding, this.questionPoints)
      this.myStanding = myStanding;
    },
    questionState(data) {
      this.questionSeed = data.questionSeed;
      this.questionState = data.state;
      this.currQuestionIndex = data.currQuestionIndex;
    },
    waitingResponses(){
      this.waitingResponses = true
    }
  },
  mounted() {
    this.timerWebWorkers = new Worker(new URL("@/workers/CountdownWorker.js", import.meta.url));
    this.timerWebWorkers.onmessage = ({ data }) => {
      // console.log(data)
      this.remainingTimeQuestion = data.timeNormalized
      this.remainingTimeQuestionString = data.timeString.replace("00:0", "").replace("00:", "")
      if (data.timeNormalized <= 0.01) this.showOptions = false
    }
    this.$sfxLoad(this.options.sounds)
  },
  beforeDestroy() {
    console.log("Saliendo de la trivia")
    this.myTimeouts.forEach(to => {
      clearTimeout(to)
    })
    this.myTimeouts = []
  }
};
</script>
