var shuffleSeed = require("shuffle-seed");

function Shuffle(_array){
  let arr = JSON.parse(JSON.stringify(_array)); 
  return shuffleSeed.shuffle(arr, Date.now);
}

function GetQuestions(_questions, seed, limit = 999) {
  let questions = JSON.parse(JSON.stringify(_questions));
  questions = shuffleSeed.shuffle(questions, seed);
  questions = questions.slice(0, limit);
  console.log(questions, limit);
  return questions;
}

function GetOptions(_question, seed) {
  // clono el objeto question para no modificar el original
  console.log("getoptions question", _question)
  let question = JSON.parse(JSON.stringify(_question));
  // console.log("🎰 Run shuffle seed", question, seed);

  // A las incorrect les agrego una propiedad guessed
  question.incorrect = question.incorrect.map(x => ({...x, guessed: [] }))
  // Conformo el array de opciones
  let options = [
    { id: question.id, option: question.correct, guessed: [] },
    ...question.incorrect,
  ];
  // Y los mezclo usando el id de la pregunta como seed
  options = shuffleSeed.shuffle(options, seed);
  question.options = options
  delete question.correct;
  delete question.incorrect;
  question.options.forEach((val, i) => {
    val.index = i
  })
  return question;
}

function intToChar(int) {
  // https://bobbyhadz.com/blog/javascript-convert-integer-to-character#:~:text=To%20convert%20an%20integer%20to,character%20equivalent%20of%20the%20integer.
  // 👇️ for Uppercase letters, replace `a` with `A`
  const code = "A".charCodeAt(0);
  // console.log(code); // 👉️ 97
  return String.fromCharCode(code + int);
}

module.exports = {
  Shuffle,
  GetQuestions,
  GetOptions,
  intToChar,
};
