<template><span>{{ animatedNumber }}</span></template>

<script>
import gsap from "gsap";

export default {
  data() {
    return {
      tweenedNumber: 0
    }
  },
  props: {
    initialNumber: {
      type: Number,
    },
    number: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: .5
    }
  },
  computed: {
    animatedNumber: function () {
      return this.tweenedNumber.toFixed(0);
    }
  },
  watch: {
    initialNumber(val) {
      console.log("initialNumber**", val)
      this.tweenedNumber = val
    },
    number: function (newValue) {
      // if (this.initialNumber) {
      //   gsap.fromTo(this.$data, { tweenedNumber: this.initialNumber }, { duration: this.duration, tweenedNumber: newValue });
      // } else {
      gsap.to(this.$data, { duration: this.duration, tweenedNumber: newValue });
      // }
    }
  }
}
</script>